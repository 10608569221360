import * as React from 'react';
import { Heading, Center, Stack, Text } from '@chakra-ui/react';


export const HomePage = () => {
  return (
    <>
      <Stack spacing={[3, 5]} align="center" pb={["20px", "40px"]}>
        <Center>
          <Heading fontSize={["2xl", "4xl"]} px="10x" pt={["10px", "20px"]}>Admin Home</Heading>
        </Center>
        <Center maxWidth={["lg", "xl"]}>
          <Text fontSize={["lg", "1xl"]} px={["24px", "20px"]}>Will eventually have links to all admin pages, and maybe show which question groups are live right now.</Text>
        </Center>
      </Stack>
    </>
  );
};
