import React, { useEffect, useState, useRef } from 'react';
import {
  useParams,
  useHistory
} from 'react-router-dom';
import { isEmpty, map, size, trim } from 'lodash';
import {
  Stack, Text, Heading, useBreakpointValue, Box, Link, Spinner,
  Center, Wrap, WrapItem, FormControl, Divider, useToast
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';

import { fetchQuestionGroups, createEmailSignup } from '../api/Api';
import { StyledButton } from '../styled-components/StyledButton';
import { StyledInput } from '../styled-components/StyledInput';
import { StyledIconButton } from '../styled-components/StyledIconButton';
import { RequestEventForm } from '../containers/RequestEventForm';
import { Faq } from '../components/Faq';
import { PropClashLogo } from '../components/PropClashLogo';
import { QuestionGroupCard } from '../components/QuestionGroupCard';
import { IQuestionGroup } from '../interfaces/Models';
import { emailRegex } from '../utils/InputUtils';


const SUBPAGE_ROUTES = [
  {
    route: null,
    title: 'Home',
  },
  {
    route: 'faq',
    title: 'FAQ',
  },
  {
    route: 'contact',
    title: 'Contact Us',
  },
  {
    route: 'request',
    title: 'Request Event',
  }
];

interface IRouteParams {
  subpage: string;
}

export const HomePage = () => {
  document.title = `Prop Bets Games`;
  
  const history = useHistory();
  const toast = useToast();

  const params = useParams<IRouteParams>();
  const subpage = params.subpage;

  const pageButtonSize = useBreakpointValue(["sm", "md"]);
  const logoSvgDimension = useBreakpointValue(["100", "200"]);

  const onSubpageClick = (subpage: string) => {
    return () => subpage ? history.push(`/home/${subpage}`) : history.push(`/home`);
  }

  const [isLoadingQuestionGroups, setIsLoadingQuestionGroups] = useState(false);
  const [questionGroups, setQuestionGroups] = useState<IQuestionGroup[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoadingQuestionGroups(true);
      const { data, hasError } = await fetchQuestionGroups();
      if (data && !hasError) {
        setQuestionGroups(data);
      }
      setIsLoadingQuestionGroups(false);
    })();
  }, []);

  const emailInputSize = useBreakpointValue(["sm", "md"]);
  const emailWidth = ["xs", "sm"];
  const [email, setEmail] = useState('');
  const submitEmailButtonSize = useBreakpointValue(["xs", "sm"]);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);

  const onSubmitEmail = async () => {
    setIsSubmittingEmail(true);
    const formattedEmail = trim(email);
    if (!emailRegex.test(formattedEmail)) {
      toast({
        description: "Please enter a valid email",
        status: "error",
        isClosable: true,
      });
      setIsSubmittingEmail(false);
      return;
    }
    const { hasError } = await createEmailSignup(formattedEmail);
    if (hasError) {
      toast({
        description: "There was an issue - please try again",
        status: "error",
        isClosable: true,
      });
      setIsSubmittingEmail(false);
      return;
    }
    toast({
      description: "Successfully signed up!",
      status: "success",
      isClosable: true,
    });
    setEmail('');
    setIsSubmittingEmail(false);
  }

  const socialButtons = (
    <Stack direction="row" spacing={[4, 8]} align="center">
      <StyledIconButton
        onClick={() => {
          window.open(
            'https://twitter.com/propclash',
            '_blank'
          );
        }}
        aria-label="twitter"
        colorScheme="twitter"
        icon={<FaTwitter/>}
      />
      <StyledIconButton
        onClick={() => {
          window.open(
            'https://www.tiktok.com/@propclash',
            '_blank'
          );
        }}
        aria-label="tiktok"
        colorScheme="tiktok"
        icon={<SiTiktok/>}
      />
      <StyledIconButton
        onClick={() => {
          window.open(
            'https://www.instagram.com/propclash',
            '_blank'
          );
        }}
        aria-label="instagram"
        colorScheme="instagram"
        icon={<FaInstagram/>}
      />
    </Stack>
  );

  const renderSubHeader = () => {
    if (isLoadingQuestionGroups) {
      return <>&nbsp;</>;
    } else if (isEmpty(questionGroups)) {
      return <>Super Bowl LVIII coming soon</>;
    } else if (size(questionGroups) === 1) {
      const questionGroup = questionGroups[0];
      return <>{questionGroup.name} is available</>;
    } else {
      return <>{questionGroups.length} available event{questionGroups.length > 1 ? 's' : ''}</>;
    }
  };

  return (
    <Stack spacing={[3, 5]} align="center" pb={["20px", "40px"]}>
      <Center marginTop={["40px", "40px"]}>
        <Heading fontSize={["3xl", "5xl"]} px="10x">🏈 SB LVIII Prop Bets 🏈</Heading>
      </Center>
      <Stack direction="row" spacing={[1, 4]} align="center">
        {map(SUBPAGE_ROUTES, (subpageRoute, idx) => {
          return (
            <StyledButton
              key={idx}
              onClick={onSubpageClick(subpageRoute.route)}
              size={pageButtonSize}
              paddingX={[2, 6]}
              useDarkMode
              colorScheme="brandColorScheme"
              variant={((!subpageRoute.route && !subpage) || (subpage === subpageRoute.route)) ? 'filled' : 'ghost'}
            >
              {subpageRoute.title}
            </StyledButton>
          );
        })}
      </Stack>
      {!subpage &&
        <>
          <Heading fontSize={["lg", "xl"]} px="10px" color="brandColorScheme.300">{renderSubHeader()}</Heading>
          <Text fontSize={["md", "lg"]} maxWidth={["lg", "2xl"]} px="24px" align="left">
            Welcome to Prop Bets Games! We've created the easiest way to play questionnaire games with your friends for the Super Bowl this year.
            If you already know how this works, create your group and send the unique link out!
          </Text>
          <Text fontSize={["md", "lg"]} maxWidth={["lg", "2xl"]} px="24px" align="left">
            Confused? Read through the <Link color="brandColorScheme.300" onClick={onSubpageClick('faq')}>FAQ</Link> to understand what this is all about.
          </Text>
          <Wrap justify="center" align="center" spacing={["10px", "20px"]}>
            {isLoadingQuestionGroups ?
              <Spinner
                thickness="5px"
                speed="1s"
                size="xl"
              />
              :
              isEmpty(questionGroups) ?
                <Text fontSize={["lg", "xl"]} maxWidth={["lg", "2xl"]} px="24px" align="center">
                </Text>
                :
                <>
                  {map(questionGroups, (questionGroup) => {
                    return (
                      <WrapItem>
                        <QuestionGroupCard questionGroup={questionGroup} />
                      </WrapItem>
                    );
                  })}
                </>
            }
          </Wrap>
          <Box paddingTop={["10px", "20px"]}>
            {socialButtons}
          </Box>
        </>
      }
      {subpage === 'faq' &&
        <Faq />
      }
      {subpage === 'contact' &&
        <>
          <Text fontSize={["md", "lg"]} maxWidth={["lg", "2xl"]} px="24px" align="left">
            Have any questions or want to give us some feedback? Feel free to email us at <Link target="_blank" color="brandColorScheme.300" href="mailto:propbetsgames@gmail.com">propbetsgames@gmail.com</Link>.
          </Text>
          <Text fontSize={["md", "lg"]} maxWidth={["lg", "2xl"]} px="24px" align="left">
            Give us a follow on our socials as well to always be in the loop for new and exciting developments!
          </Text>
          {socialButtons}
        </>
      }
      {subpage === 'request' &&
        <>
          <Text fontSize={["md", "lg"]} maxWidth={["lg", "xl"]} px="24px" align="left">
            Want to see a specific event? Or even host your own custom event? Fill out this form and we'll get back to you ASAP.
          </Text>
          <RequestEventForm />
        </>
      }
    </Stack>
  )
};
