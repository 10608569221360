import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { sum, trim } from 'lodash';
import {
  Stack, FormLabel, FormControl, useBreakpointValue, InputGroup,
  FormHelperText, VStack, Center, Link
} from '@chakra-ui/react';

import { createGroup } from '../api/Api';
import { StyledInput } from '../styled-components/StyledInput';
import { StyledButton } from '../styled-components/StyledButton';
import { StyledInputAddon } from '../styled-components/StyledInputAddon';
import { StyledTextarea } from '../styled-components/StyledTextarea';
import { AwardDistributionInput } from '../components/AwardDistributionInput';
import { emailRegex, numberRegex } from '../utils/InputUtils';
import { IGroupInput } from '../interfaces/Inputs';

interface IProps {
  questionGroupId: string;
}

export const CreateGroupForm = (props: IProps) => {
  const history = useHistory();
  
  const inputSize = useBreakpointValue(["sm", "md"]);
  const formControlWidth = ["sm", "xl"];

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const isEmailInvalid = email && !emailRegex.test(email);

  const [notes, setNotes] = useState("");
  const [entryFee, setEntryFee] = useState("0");
  const [entryFeeInstructions, setEntryFeeInstructions] = useState("");
  const [awardDistributions, setAwardDistributions] = useState(["100", "0", "0"]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);

  const setAwardDistributionForIndex = (index: number) => {
    return (value: string) => {
      const newAwardDistributions = [...awardDistributions];
      newAwardDistributions[index] = value;
      setAwardDistributions(newAwardDistributions);
    };
  };

  const numericAwardDistributions = awardDistributions.map((strNum) => parseFloat(strNum) ? parseFloat(strNum) : 0);

  const isAwardDistributionsInvalid = sum(numericAwardDistributions) !== 100;

  const isSubmitDisabled = Boolean(!name || (Number(entryFee) && isAwardDistributionsInvalid) || !emailRegex.test(email));

  const onSubmit = async () => {
    setIsSubmitting(true);

    const groupInput: IGroupInput = {
      name: trim(name),
      notes,
      email: trim(email),
      questionGroupId: props.questionGroupId,
      entryFee: parseFloat(entryFee),
      entryFeeInstructions,
      awardDistributions: numericAwardDistributions,
    };

    const { data: group, hasError } = await createGroup(groupInput);

    if (hasError) {
      setIsSubmitting(false);
      setHasApiError(true);
      return;
    }

    const groupUrlId = group.urlId;
    const groupPassword = group.password;
    const qsDict = {
      code: groupPassword,
    };
    const qs = queryString.stringify(qsDict);

    setIsSubmitting(false);
    setHasApiError(false);
    history.push(`/group/${groupUrlId}?${qs}`, {
      showOnGroupCreateModal: true,
    });
  }

  return (
    <Stack spacing={[3, 5]} width="100%" align="center">
      <FormControl isRequired maxWidth={formControlWidth} px="10px">
        <FormLabel>Group Name</FormLabel>
        <StyledInput
          variant="filled"
          size={inputSize}
          value={name}
          onChange={(event) => setName(event.target.value)}
          colorScheme="gray"
        />
      </FormControl>
      <FormControl isRequired maxWidth={formControlWidth} px="10px">
        <FormLabel>Email</FormLabel>
        <StyledInput
          colorScheme="gray"
          variant="filled"
          size={inputSize}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          isInvalid={isEmailInvalid}
        />
        {isEmailInvalid && 
          <FormHelperText color="red.300">Please enter a valid email.</FormHelperText>
        }
      </FormControl>
      <FormControl maxWidth={formControlWidth} px="10px">
        <FormLabel>Notes</FormLabel>
        <StyledTextarea
          height={["5.4rem", "5.65rem"]} 
          colorScheme="gray"
          variant="filled"
          size={inputSize}
          placeholder="Enter any notes you want your participants to see. This will show up on the top of your group page."
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
        />
      </FormControl>
      <FormControl maxWidth={formControlWidth} px="10px">
        <FormLabel>Entry Fee</FormLabel>
        <InputGroup size={inputSize}>
          <StyledInputAddon
            colorScheme="gray"
            placement="left"
            children="$"
            border="0"
          />
          {/* TODO: Why does this min not work? */}
          <StyledInput
            colorScheme="gray"
            variant="filled"
            type="number"
            pattern={numberRegex.source}
            value={entryFee}
            onChange={(event) => setEntryFee(event.target.value)}
            onBlur={(event) => event.target.value === "" ? setEntryFee("0") : null}
            borderLeftRadius="0"
          />
        </InputGroup>
      </FormControl>
      {parseFloat(entryFee) &&
        <>
          <FormControl maxWidth={formControlWidth} px="10px">
            <FormLabel>Entry Fee Instructions</FormLabel>
            <StyledTextarea
              height={["5.4rem", "5.65rem"]} 
              colorScheme="gray"
              variant="filled"
              size={inputSize}
              placeholder="How should people pay the entry fee? (e.g. Venmo @username or PayPal name@email.com)"
              value={entryFeeInstructions}
              onChange={(event) => setEntryFeeInstructions(event.target.value)}
            />
          </FormControl>
          <FormControl maxWidth={formControlWidth} px="10px">
            <FormLabel>Award Distribution</FormLabel>
            <VStack>
              {["1st", "2nd", "3rd"].map((label, index) => {
                return (
                  <AwardDistributionInput
                    key={index}
                    label={label}
                    value={awardDistributions[index]}
                    setAwardDistribution={setAwardDistributionForIndex(index)}
                    inputSize={inputSize}
                  />
                )
              })}
            </VStack>
            {isAwardDistributionsInvalid &&
              <FormHelperText color="red.300">All percents must add up to 100.</FormHelperText>
            }
          </FormControl>
        </>
      }
      <FormControl maxWidth={formControlWidth} px="10px">
        <Center>
          <VStack pt={["10px", "20px"]}>
            <StyledButton
              colorScheme="brandColorScheme"
              size={inputSize}
              isDisabled={isSubmitDisabled}
              isLoading={isSubmitting}
              loadingText={"Creating 🎉"}
              onClick={onSubmit}
            >
              Create 🎉
            </StyledButton>
            {hasApiError &&
              <FormHelperText px="10px" color="red.300">Looks like there was an error creating your group. Try again! If you continue to have issues please email <Link color="brandColorScheme.300" href="mailto:propbetsgames@gmail.com">propbetsgames@gmail.com</Link>.</FormHelperText>
            }
          </VStack>
        </Center>
      </FormControl>
    </Stack>
  );
};
