import React from 'react';
import { isEmpty } from 'lodash';
import { FormControl, FormLabel, FormHelperText } from '@chakra-ui/react';

import { StyledInput } from '../styled-components/StyledInput';
import { numberRegex, widthNumToString } from '../utils/InputUtils';
import { IQuestion } from '../interfaces/Models';

interface IProps {
  question: IQuestion;
  order: number;
  inputNumber: string;
  setAnswerNumber: (value: string) => void;
  inputSize: string | undefined;
  inputWidth: number | undefined; // in rem
}

export const NumberQuestionInput = (props: IProps) => {
  const { question, order, inputNumber, setAnswerNumber, inputSize, inputWidth } = props;

  let inputColorScheme = !isEmpty(question.numberColorScheme) ? `${question.id}` : 'brandColorScheme';
  inputColorScheme = inputNumber ? inputColorScheme : 'whiteAlpha';

  return (
    <FormControl maxWidth={widthNumToString(inputWidth)}>
      {question.title &&
        <FormLabel fontWeight="bold">{question.title}</FormLabel>
      }
      <FormLabel>{order + 1}. {question.multiplier && question.multiplier > 1 ? `(${question.multiplier}x)` : ''} {question.text}</FormLabel>
      <StyledInput
        colorScheme={inputColorScheme}
        variant={parseFloat(inputNumber) >= 0 ? "filled" : "outline"}
        min={"0"}
        pattern={numberRegex.source}
        value={inputNumber}
        onChange={(event) => setAnswerNumber(event.target.value)}
        size={inputSize}
        type="number"
      />
      {question.helpText &&
        <FormHelperText>{question.helpText}</FormHelperText>
      }
    </FormControl>
  );
}
