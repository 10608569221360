import { getEnvironment, Environment} from '../utils/Environment';

const LOCAL_API_URL = 'http://localhost:5501/prop-bets-dev/us-central1/api';
const DEVELOPMENT_API_URL = 'https://us-central1-prop-bets-dev.cloudfunctions.net/api';
const PRODUCTION_API_URL = 'https://us-central1-prop-bets-1a472.cloudfunctions.net/api';

const currentEnvironment = getEnvironment();

const apiUrl = () => {
  switch (currentEnvironment) {
    case Environment.PRODUCTION:
      return PRODUCTION_API_URL;
    case Environment.DEVELOPMENT:
      return DEVELOPMENT_API_URL;
    case Environment.LOCALHOST:
      return LOCAL_API_URL
    default:
      console.error('Invalid environment type received');
      return DEVELOPMENT_API_URL;
  }
}

export default apiUrl;
