import React, { useState } from 'react';
import { FaPalette } from 'react-icons/fa';
import { SketchPicker } from 'react-color';
import {
  Popover, PopoverTrigger, PopoverContent
} from '@chakra-ui/react';

import { StyledIconButton } from '../styled-components/StyledIconButton';

const PRESET_COLORS = [
  '#C70833',
  '#E53E3E',
  '#F6AD55',
  '#F6E05E',
  '#48BB78',
  '#4FD1C5',
  '#4299E1',
  '#0BC5EA',
  '#805AD5',
  '#D53F8C',
  '#718096',
];

interface IProps {
  defaultColor?: string;
  onColorChange: (colorHex: string) => void;
  buttonSize?: string;
}

export const ColorPickerIcon = (props: IProps) => {
  const [colorInput, setColorInput] = useState(props.defaultColor || '#82898f');

  return (
    <Popover
      placement="bottom-start"
    >
      <PopoverTrigger>
        <StyledIconButton
          size={props.buttonSize || "sm"}
          icon={<FaPalette />}
          aria-label="palette"
          backgroundColor={colorInput}
          _hover={{
            backgroundColor: colorInput
          }}
          _focus={{
            backgroundColor: colorInput,
          }}
          _active={{
            backgroundColor: colorInput,
          }}
        >
          Color
        </StyledIconButton>
      </PopoverTrigger>
      <PopoverContent
        display="inline-block"
        w="auto"
        color="black"
      >
        <SketchPicker
          color={colorInput}
          onChangeComplete={color => {
            setColorInput(color.hex);
            props.onColorChange(color.hex);
          }}
          disableAlpha
          presetColors={PRESET_COLORS}
        />
      </PopoverContent>
    </Popover>
  )
}
