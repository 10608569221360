import React, { useState, useEffect } from 'react';
import {
  Modal, ModalContent, ModalHeader, ModalCloseButton, UseDisclosureProps, ModalOverlay, ModalBody,
  ModalFooter, Text, DarkMode, useBreakpointValue, Center, Stack, Box
} from '@chakra-ui/react';
import { isFunction, map, forEach, isEmpty } from 'lodash';

import { fetchQuestionsByQuestionGroupId } from '../api/Api';
import { StyledButton } from '../styled-components/StyledButton';
import { IQuestionDict, IQuestionGroup, QuestionTypes } from '../interfaces/Models';

interface IProps extends UseDisclosureProps {
  questionGroup: IQuestionGroup | null;
  primaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
}

export const QuestionGroupPreviewModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    questionGroup,
    primaryButtonText,
    onPrimaryButtonClick,
  } = props;
  const modalSize = useBreakpointValue(["xs", "md"]);

  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [questionDict, setQuestionDict] = useState<IQuestionDict>();


  useEffect(() => {
    if (questionGroup?.id) {
      (async () => {
        setIsLoadingQuestions(true);
        const { data: questionsData, hasError: fetchQuestionsError } = await fetchQuestionsByQuestionGroupId(questionGroup.id);
        const questionDict = {};
        forEach(questionsData, (question) => {
          questionDict[question.id] = { ...question };
        })
        setQuestionDict(questionDict);
        setIsLoadingQuestions(false);
      })();
    }
  }, [questionGroup?.id]);

  // load all questions

  return (
    <DarkMode>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={modalSize}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent backgroundColor={"background"}>
          <ModalHeader>{questionGroup?.name} questions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!isEmpty(questionDict) &&
              <Stack spacing={[1, 2]}>
                {map(questionGroup?.questionIds, (questionId, index) => {
                  const question = questionDict[questionId];
                  return (
                    <Box key={questionId}>
                      <Text
                        key={question.id}
                        fontSize={["md", "lg"]}
                      >
                        <b>{index + 1}.</b> {question.text}
                      </Text>
                    </Box>
                  );
                })}
              </Stack>
            }
          </ModalBody>
          <ModalFooter>
            {primaryButtonText && isFunction(onPrimaryButtonClick) &&
              <StyledButton
                mr={3}
                colorScheme="brandColorScheme"
                onClick={onPrimaryButtonClick}
              >
                {primaryButtonText}
              </StyledButton>
            }
            <StyledButton
              colorScheme="brandColorScheme"
              onClick={onClose}
              variant="ghost"
            >
              Close
            </StyledButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkMode>
  );
};
