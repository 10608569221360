import React, { useContext } from 'react';
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect
} from 'react-router-dom';
import { ChakraProvider, Box, ColorModeProvider } from '@chakra-ui/react';

import { ThemeContext } from './contexts/ThemeState';
import { Fonts } from './components/Fonts';
import { CreateGroupPage } from './pages/CreateGroupPage';
import { GroupPage } from './pages/GroupPage';
import { HomePage } from './pages/HomePage';
import { HomePage as AdminHomePage } from './pages/admin/HomePage';
import { QuestionPage as AdminQuestionPage } from './pages/admin/QuestionPage';
import { QuestionGroupPage as AdminQuestionGroupPage } from './pages/admin/QuestionGroupPage';
import { CreateQuestionGroupPage } from './pages/admin/CreateQuestionGroupPage';

export const InnerApp = () => {
  const { theme } = useContext(ThemeContext);


  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider options={{
        initialColorMode: "dark",
        useSystemColorMode: false,
      }}>
        <Fonts />
        <Box bg="background" minHeight="100vh">
          <BrowserRouter>
            <Switch>
              <Route path="/create-group/:questionGroupId">
                <CreateGroupPage />
              </Route>
              <Route path="/group/:id">
                <GroupPage />
              </Route>
              <Route path="/home/:subpage?">
                <HomePage />
              </Route>
              <Route
                path="/sblv-survey"
                component={() => {
                  window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSe5Ik3DBGvUxVSiSH5orzg9p3Ba2uyyMbAH3WjsnxTZ-kaQrA/viewform?usp=sf_link";
                  return null;
                }}
              />
              <Route exact path="/admin">
                <AdminHomePage />
              </Route>
              <Route path="/admin/create-question-group">
                <CreateQuestionGroupPage />
              </Route>
              <Route path="/admin/question/:questionId">
                <AdminQuestionPage />
              </Route>
              <Route path="/admin/question-group/:questionGroupId">
                <AdminQuestionGroupPage />
              </Route>
              <Route>
                <Redirect to="/home" />
              </Route>
            </Switch>
          </BrowserRouter>
        </Box>
      </ColorModeProvider>
    </ChakraProvider> 
  );
};
