import React from 'react';
import { InputGroup } from '@chakra-ui/react';

import { numberRegex } from '../utils/InputUtils';
import { StyledInput } from '../styled-components/StyledInput';
import { StyledInputAddon } from '../styled-components/StyledInputAddon';

interface IProps {
  label: string;
  value: string;
  setAwardDistribution: (value: string) => void;
  inputSize: string | undefined;
}

export const AwardDistributionInput = (props: IProps) => {
  const { label, value, setAwardDistribution, inputSize } = props;
  
  return (
    <InputGroup width={["12em", "16em"]} size={inputSize}>
      <StyledInputAddon
        colorScheme="gray"
        placement="left"
        width="3.5em"
        children={label}
        border="0"
      />
      <StyledInput
        colorScheme="gray"
        ml="5px"
        borderRadius="0"
        variant="filled"
        value={value}
        type="number"
        pattern={numberRegex.source}
        onChange={(event) => setAwardDistribution(event.target.value)}
        onBlur={(event) => event.target.value === "" ? setAwardDistribution("0") : null}
      />
      <StyledInputAddon
        colorScheme="gray"
        placement="right"
        children="%"
        border="0"
      />
    </InputGroup>
  );

}