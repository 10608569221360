import React, { useState, createContext } from 'react';
import { merge } from 'lodash';

import { Theme, extendTheme } from '@chakra-ui/react';

interface ISetThemeContext {
  theme: Partial<Theme>,
  updateTheme: (values: Partial<Theme>) => void
}

const initialContext = {
  theme: {},
  updateTheme: (values: Partial<Theme>) => console.log('hi'),
}

export const ThemeContext = createContext<ISetThemeContext>(initialContext);

export const ThemeState = (props: any) => {
  const [theme, setTheme] = useState<Theme>(extendTheme({
    breakpoints: ["20em", "30em"],
    colors: {
      background: "#3A4750", // make sure to update index.html if you change this
      // Use https://smart-swatch.netlify.app/ to generate these swatches
      // brandColorScheme: {
      //   50: '#fff3dd',
      //   100: '#fcdeb4',
      //   200: '#f5c887',
      //   300: '#f0b25a',
      //   400: '#ec9d2c',
      //   500: '#d38313',
      //   600: '#a4660c',
      //   700: '#764907',
      //   800: '#482b00',
      //   900: '#1c0e00',
      // },
      brandColorScheme: {
        50: '#e7fae5',
        100: '#c5ebc4',
        200: '#a1dca0',
        300: '#7dce7c',
        400: '#59c058',
        500: '#40a73f',
        600: '#318230',
        700: '#205d21',
        800: '#103912',
        900: '#001500',
      },
      instagram: {
        50: '#ffe7fa',
        100: '#f5bee3',
        200: '#ea96cf',
        300: '#df6cba',
        400: '#d543a6',
        500: '#bc2a8d',
        600: '#93206d',
        700: '#69144f',
        800: '#420a30',
        900: '#1b0114',
      },
      tiktok: {
        50: '#f2f2f2',
        100: '#d9d9d9',
        200: '#bfbfbf',
        300: '#a6a6a6',
        400: '#8c8c8c',
        500: '#737373',
        600: '#595959',
        700: '#404040',
        800: '#262626',
        900: '#0d0d0d',
      },
    },
    fonts: {
      heading: "Poppins",
      body: "Poppins"
    },
    config: {
      initialColorMode: "dark",
      useSystemColorMode: false,
    }
  }));


  const updateTheme = (values: Partial<Theme>) => {
    const newTheme = merge(theme, values);
    setTheme(extendTheme(newTheme));
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        updateTheme
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}
