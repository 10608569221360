import React from 'react';

import { InnerApp } from './InnerApp';
import { ThemeState } from './contexts/ThemeState';

export const App = () => {
  return (
    <ThemeState>
      <InnerApp />
    </ThemeState>
  );
};
