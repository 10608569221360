import React, { forwardRef } from 'react';
import { Button, ButtonProps, DarkMode, LightMode } from '@chakra-ui/react';

interface IProps extends ButtonProps {
  useDarkMode?: boolean;
}

export const StyledButton = forwardRef((props: IProps, ref?: any) => {
  const styledButton = (
      <Button
      colorScheme="whiteAlpha"
      _focus={{
        boxShadow: "none !important"
      }}
      ref={ref}
      {...props}
    />
  );

  return (
    <>
      {props.useDarkMode ? 
        <DarkMode>
          {styledButton}
        </DarkMode>
        :
        <LightMode>
          {styledButton}
        </LightMode>
      }
    </>
  );
});

StyledButton.defaultProps = {
  useDarkMode: false,
};
