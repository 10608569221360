import React from 'react';
import {
  Modal, ModalContent, ModalHeader, ModalCloseButton, UseDisclosureProps, ModalOverlay, ModalBody,
  ModalFooter, Text, DarkMode, useBreakpointValue, Link, Stack
} from '@chakra-ui/react';
import { StyledButton } from '../styled-components/StyledButton';

interface IProps extends UseDisclosureProps {
  groupUrl: string;
}

export const OnGroupCreateModal = (props: IProps) => {
  const { isOpen, onClose, groupUrl } = props;
  const modalSize = useBreakpointValue(["xs", "md"]);

  const onCopyLinkClick = () => {
    navigator.clipboard.writeText(groupUrl);
  };

  return (
    <DarkMode>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={modalSize}
      >
        <ModalOverlay />
        <ModalContent backgroundColor={"background"}>
          <ModalHeader>Here's your group!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={[2, 4]}>
              <Text>
                The following is your unique link to share. Send it out to your friends, family, whoever!
              </Text>
              <Text align="center">
                <Link color="brandColorScheme.300" href={groupUrl}>
                  {groupUrl}
                </Link>
              </Text>
              <Text>
                Remember, you can also just copy/paste the url at the top of your group page as well.
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <StyledButton
              mr={3}
              colorScheme="brandColorScheme"
              onClick={onCopyLinkClick}
            >
              Copy Link
            </StyledButton>
            <StyledButton
              colorScheme="brandColorScheme"
              onClick={onClose}
              variant="ghost"
            >
              Close
            </StyledButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkMode>
  );
};
