import React from 'react';

import { Select, SelectProps } from '@chakra-ui/react';

interface IProps extends SelectProps {
  colorScheme: string;
}

export const StyledSelect = (props: IProps) => {
  const { colorScheme, fontWeight } = props;

  return (
    <Select
      bgColor={`${colorScheme}.500`}
      color="white"
      fontWeight={fontWeight || "bold"}
      _hover={{
        backgroundColor: `${colorScheme}.600`
      }}
      _focus={{
        backgroundColor: `${colorScheme}.500`
      }}
      {...props}
    />
  );
}
