import { find } from 'lodash';

import { IQuestionChoice } from '../interfaces/Models';

export const getChoiceById = (choices: IQuestionChoice[], choiceId: string): IQuestionChoice => {
  return find(choices, (c) => c.id === choiceId);
}

export const formatDate = (date: string): string => {
  return new Date(date).toLocaleString(
    'en-US',
    {
      month: 'short',
      day: 'numeric',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
  });
}

export const TROPHY_EMOJIS = ["🥇", "🥈", "🥉"];

export const sleep = async (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
