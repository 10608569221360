import axios, { Method, AxiosResponse } from 'axios';

import apiUrl from './ApiUrl';
import { IGroupInput, IEntryInput, IQuestionInput, IEventInput, IQuestionGroupInput } from '../interfaces/Inputs';
import { IGroup, IQuestionGroup, IQuestion, IEntry, IEvent, IEntryWithGroup } from '../interfaces/Models';

export enum ENDPOINT {
  QUESTIONS='questions',
  QUESTION_GROUPS='question-groups',
  GROUPS='groups',
  ENTRIES='entries',
  EVENTS='events',
  EMAIL_SIGNUPS='email-signups',
  ADMIN='admin',
}

export interface IApiResponse<IData> {
  data: IData;
  hasError: boolean;
  statusCode: number;
}

const HEADERS = {
  'Content-Type': 'application/json',
};

const makeApiCall = async (
  endpoint: string,
  method: Method,
  params: any = {},
  body: any = {},
  apiKey?: string,
): Promise<IApiResponse<any>> => {
  const url = `${apiUrl()}/${endpoint}`;

  let response: AxiosResponse;

  const headers = {...HEADERS};
  if (apiKey) {
    headers['Authorization'] = apiKey;
  }

  try {
    response = await axios.request({
      method,
      url,
      headers,
      params,
      data: body,
    });
  } catch (error) {
    response = error.response;
  }

  return {
    data: response.data,
    statusCode: response.status,
    hasError: response.status >= 400,
  }
}

export const createEvent = async (
  eventInput: IEventInput,
  apiKey: string,
): Promise<IApiResponse<IEvent>> => {
  return makeApiCall(ENDPOINT.EVENTS, 'POST', {}, eventInput, apiKey);
};

export const createQuestionsBulk = async (
  questionsInput: IQuestionInput[],
  apiKey: string,
): Promise<IApiResponse<IQuestion[]>> => {
  return makeApiCall(`${ENDPOINT.QUESTIONS}/bulk/create`, 'POST', {}, questionsInput, apiKey);
};

export const createQuestionGroup = async (
  questionGroupInput: IQuestionGroupInput,
  apiKey: string,
): Promise<IApiResponse<IQuestionGroup>> => {
  return makeApiCall(ENDPOINT.QUESTION_GROUPS, 'POST', {}, questionGroupInput, apiKey);
};

export const createGroup = async (
  groupInput: IGroupInput
): Promise<IApiResponse<IGroup>> => {
  return makeApiCall(ENDPOINT.GROUPS, 'POST', {}, groupInput);
};

export const fetchGroupByUrlId = async (
  urlId: string,
  password: string
): Promise<IApiResponse<IGroup>> => {
  const queryParams = {
    urlId,
    password,
  }
  return makeApiCall(ENDPOINT.GROUPS, 'GET', queryParams);
}

export const fetchGroupById = async (
  id: string,
  password: string
): Promise<IApiResponse<IGroup>> => {
  const queryParams = {
    password,
  }
  return makeApiCall(`${ENDPOINT.GROUPS}/${id}`, 'GET', queryParams);
}

export const fetchQuestionGroupById = async (
  id: string
): Promise<IApiResponse<IQuestionGroup>> => {
  return makeApiCall(`${ENDPOINT.QUESTION_GROUPS}/${id}`, 'GET');
}

export const fetchQuestionGroups = async (): Promise<IApiResponse<IQuestionGroup[]>> => {
  return makeApiCall(`${ENDPOINT.QUESTION_GROUPS}`, 'GET');
}

export const fetchQuestionById = async (
  id: string
): Promise<IApiResponse<IQuestion>> => {
  return makeApiCall(`${ENDPOINT.QUESTIONS}/${id}`, 'GET');
}

export const updateQuestion = async (
  id: string,
  questionInput: Partial<IQuestion>,
  apiKey: string,
): Promise<IApiResponse<IQuestion>> => {
  return makeApiCall(`${ENDPOINT.QUESTIONS}/${id}`, 'POST', {}, questionInput, apiKey);
}

export const fetchQuestionsByQuestionGroupId = async (
  questionGroupId: string
): Promise<IApiResponse<IQuestion[]>> => {
  const queryParams = {
    questionGroupId,
  };
  return makeApiCall(ENDPOINT.QUESTIONS, 'GET', queryParams);
}

export const createEntry = async (
  entryInput: IEntryInput,
  groupId: string,
  groupPassword: string
): Promise<IApiResponse<IEntry>> => {
  const queryParams = {
    groupId,
    groupPassword
  };
  return makeApiCall(ENDPOINT.ENTRIES, 'POST', queryParams, entryInput);
}

export const fetchEntriesByGroupId = async (
  groupId: string,
  groupPassword: string,
): Promise<IApiResponse<IEntry[]>> => {
  const queryParams = {
    groupId,
    groupPassword
  };
  return makeApiCall(ENDPOINT.ENTRIES, 'GET', queryParams);
}

export const fetchEntriesByQuestionGroupIdAndEmail = async (
  questionGroupId: string,
  email: string,
): Promise<IApiResponse<IEntryWithGroup[]>> => {
  const queryParams = {
    questionGroupId,
    email,
  };
  return makeApiCall(`${ENDPOINT.ENTRIES}/by-question-group-and-email`, 'GET', queryParams);
}

export const createEmailSignup = async (
  email: string,
): Promise<IApiResponse<any>> => {
  return makeApiCall(ENDPOINT.EMAIL_SIGNUPS, 'POST', {}, { email });
}

export const submitEventRequest = async (
  email: string,
  eventInfo: string,
): Promise<IApiResponse<any>> => {
  return makeApiCall(`${ENDPOINT.ADMIN}/request-event`, 'POST', {}, { email, eventInfo });
}
