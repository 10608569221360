import React from 'react';
import { Input, InputProps } from '@chakra-ui/react';

interface IProps extends InputProps {
  innerRef?: any;
  colorScheme: string;
}

export const StyledInput = (props: IProps) => {
  const { innerRef, fontWeight, colorScheme } = props;
  const errorBorderColor = "red.300"

  return (
    <Input
      ref={innerRef}
      errorBorderColor={errorBorderColor}
      bgColor={`${colorScheme}.500`}
      color="white"
      fontWeight={fontWeight || "bold"}
      _hover={{
        backgroundColor: `${colorScheme}.600`
      }}
      _focus={{
        color: `${colorScheme}.900`,
        borderColor: `${colorScheme}.700`,
        border: props.isInvalid ? '0px' : '1px solid',
        backgroundColor: `${colorScheme}.100`,
      }}
      _selected={{
        outlineColor: `${colorScheme}.900`
      }}
      {...props}
    />
  );
};
