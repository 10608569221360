import React, { useState, useEffect, useMemo } from 'react';
import { sortBy, map, find, toString, isEmpty, forEach, reduce, keys, values, mapValues, isNumber, round } from 'lodash';
import { Accordion, AccordionButton, AccordionPanel, AccordionItem, Box, AccordionIcon, Text, Badge, Flex, Spacer } from '@chakra-ui/react'

import { IEntry, IQuestionGroup, IQuestionDict, QuestionTypes } from '../interfaces/Models';
import { formatDate, TROPHY_EMOJIS } from '../utils/MiscUtils';
import { IDict } from '../utils/TypescriptUtils';

interface IInitialEntryResult {
  score: number,
  // in case of a tie, entries are ranked by how close this number is to zero
  tieBreaker: number, // positive number >= 0
}

interface IFinalEntryResult extends IInitialEntryResult {
  order: number,
  isTied: boolean,
}

interface IQuestionTotal {
  choiceTotals?: {
    [choiceId: string]: {
      count: number;
    }
  }
  numberAverage?: number;
}

const calculateInitialEntryResults = (entries: IEntry[], questionDict: IQuestionDict): IDict<IInitialEntryResult> => {
  const entryScores: IDict<IInitialEntryResult> = {};

  forEach(entries, (entry) => {
    const score = reduce(keys(entry.answers), (totalScore, questionId) => {
      const answer = entry.answers[questionId];
      const question = questionDict[questionId];
      if (isEmpty(question)) {
        return totalScore + 0;
      }
      const multiplier = question.multiplier || 1;
      if (question.type === QuestionTypes.Choice) {
        if (answer.choiceAnswerId === question.choiceResultId) {
          return totalScore + multiplier;
        }
      } else if (!question.isTieBreaker && question.type === QuestionTypes.Number) {
        if (answer.numberAnswer === question.numberResult) {
          return totalScore + multiplier;
        }
      }
      return totalScore + 0;
    }, 0);

    // Assuming only one tieBreaker per questionGroup
    const tieBreakerQuestionId = find(keys(questionDict), (questionId) => {
      return questionDict[questionId].isTieBreaker === true;
    });

    let tieBreaker = 0; // default tieBreaker to 0 if the tieBreakerQuestion has not had a result yet
    const tieBreakerNumberResult = tieBreakerQuestionId && questionDict[tieBreakerQuestionId].numberResult;
    if (tieBreakerNumberResult) {
      // Assume tieBreaker is always a number
      tieBreaker = Math.abs(
        tieBreakerNumberResult - entry.answers[tieBreakerQuestionId].numberAnswer
      );
    }

    entryScores[entry.id] = {
      score,
      tieBreaker,
    };
  });

  return entryScores;
}

const sortEntries = (entries: IEntry[], questionDict: IQuestionDict, showResults: boolean): {
    sortedEntries: IEntry[];
    entryResults?: IDict<IFinalEntryResult>
  } => {
  if (!showResults) {
    const sortedEntries = sortBy(entries, (entry) => new Date(entry.dateCreated));
    return {
      sortedEntries
    };
  }

  let sortedEntries: IEntry[];

  const initialEntryResults: IDict<IInitialEntryResult> = calculateInitialEntryResults(entries, questionDict);

  sortedEntries = [...entries];
  sortedEntries.sort((a, b) => {
    const aEntryScore = initialEntryResults[a.id];
    const bEntryScore = initialEntryResults[b.id];
    const aEntryDateMs = (new Date(a.dateCreated)).valueOf();
    const bEntryDateMs = (new Date(b.dateCreated)).valueOf();
    if (aEntryScore.score > bEntryScore.score) {
      return -1;
    } else if (aEntryScore.score < bEntryScore.score) {
      return 1;
    } else if (aEntryScore.tieBreaker < bEntryScore.tieBreaker) {
      return -1
    } else if (aEntryScore.tieBreaker > bEntryScore.tieBreaker) {
      return 1;
    } else if (aEntryDateMs < bEntryDateMs) {
      return -1;
    } else if (aEntryDateMs < bEntryDateMs) {
      return 1;
    } else {
      return 0;
    }
  });

  const finalEntryResults: IDict<IFinalEntryResult> = {};
  let order = 1;
  for (let i = 0; i < sortedEntries.length; i++) {
    const entry = sortedEntries[i];
    let isTied: boolean;
    let increaseOrder: boolean;

    const prevInitialEntryScoreResult = i === 0 ? null : initialEntryResults[sortedEntries[i-1].id];
    const nextInitialEntryScoreResult = i === sortedEntries.length - 1 ? null : initialEntryResults[sortedEntries[i+1].id];
    const currentInitialEntryScoreResult = initialEntryResults[entry.id];
    if ([prevInitialEntryScoreResult?.score, nextInitialEntryScoreResult?.score].includes(currentInitialEntryScoreResult.score)) {
      if ([prevInitialEntryScoreResult?.tieBreaker, nextInitialEntryScoreResult?.tieBreaker].includes(currentInitialEntryScoreResult.tieBreaker)) {
        isTied = true;
      }
    } else {
      isTied = true;
    }

    const isScoreTiedWithPrev = prevInitialEntryScoreResult?.score === currentInitialEntryScoreResult.score;
    const isTieBreakerTiedWithPrev = prevInitialEntryScoreResult?.tieBreaker === currentInitialEntryScoreResult.tieBreaker;
    const isTiedWithPrev = isScoreTiedWithPrev && isTieBreakerTiedWithPrev;

    const isScoreTiedWithNext = nextInitialEntryScoreResult?.score === currentInitialEntryScoreResult.score;
    const isTieBreakerTiedWithNext = nextInitialEntryScoreResult?.tieBreaker === currentInitialEntryScoreResult.tieBreaker;
    const isTiedWithNext = isScoreTiedWithNext && isTieBreakerTiedWithNext;

    const isDifferentFromPrev = (prevInitialEntryScoreResult?.score !== currentInitialEntryScoreResult.score || prevInitialEntryScoreResult?.tieBreaker !== currentInitialEntryScoreResult.tieBreaker);

    if (i === 0) {
      // first one only compare curr to next
      isTied = isTiedWithNext;
    } else if (i === sortedEntries.length - 1) {
      // last one only compare curr to prev
      isTied = isTiedWithPrev;
    } else {
      // all other compare to prev and curr
      isTied = isTiedWithNext || isTiedWithPrev;
    }

    // Increase the order if the score/tie-breaker is different than the previous one, and NOT the first one
    if (i === 0) {
      increaseOrder = false;
    } else if (isDifferentFromPrev) {
      increaseOrder = true;
    } else {
      increaseOrder = false;
    }

    if (increaseOrder) {
      order += 1;
    }

    finalEntryResults[entry.id] = {
      ...currentInitialEntryScoreResult,
      isTied,
      order,
    };
  }

  return {
    sortedEntries,
    entryResults: finalEntryResults
  };
}

const calculateGroupTotals = (entries: IEntry[], questionDict: IQuestionDict): IDict<IQuestionTotal> => {
  const questionTotals: IDict<IQuestionTotal> = mapValues(questionDict, (question) => {
    if (question.type === QuestionTypes.Choice) {
      return {
        choiceTotals: {}
      };
    } else if (question.type === QuestionTypes.Number) {
      return {};
    }
  });

  forEach(values(questionDict), (question) => {
    if (question.type === QuestionTypes.Choice) {
      forEach(values(question.choices), (choice) => {
        const choiceTotal = reduce(entries, (sum, entry) => {
          if (entry.answers[question.id].choiceAnswerId === choice.id) {
            return sum + 1;
          } else {
            return sum;
          }
        }, 0);
        questionTotals[question.id].choiceTotals[choice.id] = {
          count: choiceTotal
        }
      });
    } else if (question.type === QuestionTypes.Number) {
      const numberTotal = reduce(entries, (sum, entry) => {
        return sum + entry.answers[question.id].numberAnswer;
      }, 0);
      questionTotals[question.id] = {
        numberAverage: numberTotal / entries.length,
      }
    }
  });

  return questionTotals;
}

interface IProps {
  entries: IEntry[];
  showResults: boolean;
  isEventOver: boolean;
  questionGroup?: IQuestionGroup;
  questionDict?: IQuestionDict;
}

export const GroupEntries = (props: IProps) => {
  const { entries, showResults, isEventOver, questionGroup, questionDict } = props;
  const [entryResults, setEntryResults] = useState<IDict<IFinalEntryResult>>();
  const [sortedEntries, setSortedEntries] = useState<IEntry[]>();

  const groupTotals = useMemo(() => {
    if (showResults) {
      return calculateGroupTotals(entries, questionDict);
    } else {
      return null;
    }
  }, [entries, showResults]);

  const questions = questionDict && questionGroup?.questionIds ? map(questionGroup.questionIds, (questionId) => questionDict[questionId]) : [];

  useEffect(() => {
    const {
      sortedEntries: newSortedEntries,
      entryResults: newEntryResults
    } = sortEntries(entries, questionDict, showResults);
    setSortedEntries(newSortedEntries);
    setEntryResults(newEntryResults);
  }, [entries, questionDict, showResults]);

  return (
    <Accordion
      allowMultiple
      allowToggle
      maxWidth={["lg", "2xl"]}
      pt={["2px", "4px"]}
      px="10px"
      width="100%"
      pb={["150px", "200px"]}
    >
      {showResults &&
        <AccordionItem
          key={'totals'}
          borderTopColor="transparent"
        >
          <AccordionButton
            py={["8px", "16px"]}
            px={["8px", "16px"]}
            _focus={{
              boxShadow: "none !important"
            }}
            fontWeight="bold"
          >
            <Box flex="1" textAlign="left">
              <Text fontSize={["md", "xl"]}>GROUP TOTALS</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            {map(questions, (question, index) => {
              let showResult;
              if (question.type === QuestionTypes.Choice) {
                showResult = question.choiceResultId;
              } else if (question.type === QuestionTypes.Number) {
                showResult = isNumber(question.numberResult);
              }
              return (
                <Box key={index}>
                  <Flex>
                    <Text fontSize={["sm", "md"]}>{index + 1}. {question.multiplier && question.multiplier > 1 ? `(${question.multiplier}x)` : ''} {question.text}</Text>
                    <Spacer />
                  </Flex>
                  {question.type === QuestionTypes.Choice &&
                    map(question.choices, (choice) => {
                      const isCorrect = choice.id === question.choiceResultId;
                      const colorScheme = !isEmpty(choice.colorScheme) ? `${question.id}_${choice.id}` : 'gray'
                      const choiceText = choice.text;
                      return (
                        <Text key={choice.id} fontSize={["sm", "md"]} textAlign={["center", "center"]} pb={["2px", "4px"]}>
                          <Text as="span" pr={["4px", "8px"]}><b>{groupTotals[question.id]?.choiceTotals[choice.id]?.count}</b> -</Text>
                          <Badge
                            colorScheme={colorScheme}
                            px={["4px", "8px"]}
                            py={["0px", "2px"]}
                            fontSize={["sm", "md"]}
                          >
                            {choiceText}
                          </Badge>
                          {showResult &&
                            <Text as="span" pl={["6px", "12px"]} fontSize={["sm", "md"]}>{isCorrect ? '✅' : '❌'}</Text>
                          }
                        </Text>
                      );
                    })
                  }
                  {question.type === QuestionTypes.Number &&
                    <>
                      <Text fontSize={["sm", "md"]} textAlign={["center", "center"]} pb={["2px", "4px"]}>
                        <Text as="span" pr={["4px", "8px"]} fontWeight="bold">Avg -</Text>
                        <Badge
                          colorScheme={!isEmpty(question.numberColorScheme) ? `${question.id}` : 'gray'}
                          px={["4px", "8px"]}
                          fontSize={["sm", "md"]}
                        >
                          {round(groupTotals[question.id]?.numberAverage, 2)}
                        </Badge>
                      </Text>
                      {showResult &&
                        <Text fontSize={["sm", "md"]} textAlign={["center", "center"]}>
                          <Badge
                            colorScheme={!isEmpty(question.numberColorScheme) ? `${question.id}` : 'gray'}
                            px={["4px", "8px"]}
                            fontSize={["sm", "md"]}
                          >
                            {question.numberResult}
                          </Badge>
                          <Text as="span" pl={["6px", "12px"]} fontSize={["sm", "md"]}>✅</Text>
                        </Text>
                      }
                    </>
                  }
                </Box>
              );
            })}
          </AccordionPanel>
        </AccordionItem>
      }
      {map(sortedEntries, (entry) => {
        const entryResult = isEmpty(entryResults) ? null : entryResults[entry.id];

        return (
          <AccordionItem
            key={entry.id}
            isDisabled={!showResults}
          >
            <>
              <AccordionButton
                py={["4px", "8px"]}
                px={["8px", "16px"]}
                _disabled={!showResults ?
                  {
                    opacity: "1",
                    cursor: "default"
                  } :
                  {}
                }
                _hover={!showResults ?
                  { background: "transparent" }
                  :
                  { background: "rgba(0, 0, 0, 0.04)" }
                }
                _focus={{
                  boxShadow: "none !important"
                }}
                _expanded={{
                  fontWeight: "bold"
                }}
              >
                <Box flex="1" textAlign="left">
                  <Text fontSize={["sm", "lg"]}>
                    {showResults && entryResult &&
                      <>
                        <Text as="span" fontWeight="bold">{isEventOver && entryResult.order <= 3 ? TROPHY_EMOJIS[entryResult.order-1] : `${entryResult.order}.`}</Text>
                        {entryResult.isTied &&
                          <Text as="span" fontSize={["xs", "sm"]}> (tied)</Text>
                        }
                      </>
                    }
                    &nbsp;{entry.name}
                    {showResults && entryResult &&
                      <Badge
                        px={["4px", "8px"]}
                        ml={["10px", "16px"]}
                        mb="2px"
                        fontSize={["sm", "md"]}
                      >
                        {entryResult.score}
                      </Badge>
                    }
                  </Text>
                </Box>
                {!showResults ?
                  <Text fontSize={["sm", "lg"]}>{formatDate(entry.dateCreated)}</Text>
                  :
                  <AccordionIcon />
                }
              </AccordionButton>
              <AccordionPanel>
                {map(questions, (question, index) => {
                  const answer = entry.answers[question.id];
                  let answerText;
                  let colorScheme;
                  let showResult;
                  let isCorrect;
                  if (question.type === QuestionTypes.Choice) {
                    showResult = question.choiceResultId;
                    isCorrect = answer.choiceAnswerId === question.choiceResultId;
                    const choice = find(question.choices, (c) => c.id === answer.choiceAnswerId);
                    answerText = choice?.text;
                    colorScheme = !isEmpty(choice.colorScheme) ? `${question.id}_${choice.id}` : 'gray';
                  } else if (question.type === QuestionTypes.Number) {
                    answerText = toString(answer?.numberAnswer);
                    colorScheme = !isEmpty(question.numberColorScheme) ? `${question.id}` : 'gray';
                  }
                  return (
                    <Box key={index}>
                      <Flex>
                        <Text fontSize={["sm", "md"]}>{index + 1}. {question.multiplier && question.multiplier > 1 ? `(${question.multiplier}x)` : ''} {question.text}</Text>
                        <Spacer />
                        {showResult &&
                          <Text pl={["4px", "12px"]} fontSize={["sm", "md"]}>{isCorrect ? '✅' : '❌'}</Text>
                        }
                      </Flex>
                      <Text fontSize={["sm", "md"]} textAlign={["center", "center"]} pl={["0px", "20px"]}>
                        <Badge
                          colorScheme={colorScheme}
                          px={["4px", "8px"]}
                          py={["0px", "2px"]}
                          fontSize={["sm", "md"]}
                        >
                          {answerText}
                        </Badge>
                      </Text>
                    </Box>
                  );
                })}
              </AccordionPanel>
            </>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}
