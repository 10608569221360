import React, { useEffect, useState } from 'react';
import { values, map, keys, some, isEmpty } from 'lodash';
import { FormControl, FormLabel, ButtonGroup, FormHelperText, Select } from '@chakra-ui/react';

import { IQuestionChoice, IQuestion } from '../interfaces/Models';
import { StyledButton } from '../styled-components/StyledButton';
import { widthNumToString } from '../utils/InputUtils';
import { getChoiceById } from '../utils/MiscUtils';

interface IProps {
  question: IQuestion;
  order: number;
  inputChoiceId: string;
  setAnswerChoice: (value: string) => void;
  buttonSize: string | undefined;
  inputWidth: number | undefined; // in rem
}

const calculateUseSelect = (choices: IQuestionChoice[]): boolean => {
  if (keys(choices).length > 2) {
    return true;
  } else if (some(map(values(choices), c => c.text.length > 15))) {
    return true;
  } else {
    return false;
  }
}

export const ChoiceQuestionInput = (props: IProps) => {
  const { question, order, inputChoiceId, setAnswerChoice, buttonSize, inputWidth } = props;

  const choices = question.choices!;
  const useSelect = calculateUseSelect(choices);

  const [selectColorScheme, setSelectColorScheme] = useState('');

  useEffect(() => {
    const choice = getChoiceById(question.choices, inputChoiceId);
    let newSelectColorScheme = !isEmpty(choice?.colorScheme) ? `${question.id}_${inputChoiceId}` : 'brandColorScheme';
    newSelectColorScheme = inputChoiceId ? newSelectColorScheme : 'whiteAlpha';
    setSelectColorScheme(newSelectColorScheme);
  }, [inputChoiceId, question.id, question.numberColorScheme, question.choices]);

  return (
    <FormControl maxWidth={widthNumToString(inputWidth)}>
      {question.title &&
        <FormLabel fontWeight="bold">{question.title}</FormLabel>
      }
      <FormLabel>{order + 1}. {question.multiplier && question.multiplier > 1 ? `(${question.multiplier}x)` : ''} {question.text}</FormLabel>
      {useSelect ?
        // TODO: Replace this with StyledSelect
        <Select
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          variant={inputChoiceId ? "filled" : "outline"}
          value={inputChoiceId}
          onChange={(event) => setAnswerChoice(event.target.value)}
          size={buttonSize}
          bgColor={`${selectColorScheme}.600`}
          color="white"
          fontWeight="bold"
          _hover={{
            backgroundColor: `${selectColorScheme}.700`
          }}
          _focus={{
            backgroundColor: `${selectColorScheme}.600`
          }}
          placeholder=" "
        >
          <option key="empty" style={{ display: "none" }}></option>
          {map(choices, (answerChoice) => {
            return (
              <option key={answerChoice.id} value={answerChoice.id}>{answerChoice.text}</option>
            );
          })}
        </Select>
        :
        <ButtonGroup isAttached display="flex">
          {map(choices, (answerChoice) => {
            const colorScheme = !isEmpty(answerChoice.colorScheme) && `${question.id}_${answerChoice.id}`;
            const defaultColorScheme = 'brandColorScheme';
            if (colorScheme) {
              return (
                <StyledButton
                  key={answerChoice.id}
                  colorScheme={colorScheme}
                  isFullWidth
                  maxWidth={inputWidth && widthNumToString(inputWidth / 2)}
                  display="block"
                  variant={inputChoiceId === answerChoice.id ? "solid" : "outline"}
                  size={buttonSize}
                  onClick={() => setAnswerChoice(answerChoice.id)}
                  color={inputChoiceId === answerChoice.id ? 'white' : `${colorScheme}.400`}
                  _hover={inputChoiceId !== answerChoice.id && {
                    color: `${colorScheme}.500`,
                  }}
                  bgColor={inputChoiceId === answerChoice.id ? `${colorScheme}.600` : 'transparent'}
                >{answerChoice.text}</StyledButton>
              );
            } else {
              return (
                <StyledButton
                  key={answerChoice.id}
                  isFullWidth
                  maxWidth={inputWidth && widthNumToString(inputWidth / 2)}
                  display="block"
                  variant={inputChoiceId === answerChoice.id ? "solid" : "outline"}
                  size={buttonSize}
                  onClick={() => setAnswerChoice(answerChoice.id)}
                  color="white"
                  fontWeight="bold"
                  _hover={inputChoiceId !== answerChoice.id && {
                    backgroundColor: `${defaultColorScheme}.500`
                  }}
                  bgColor={inputChoiceId === answerChoice.id ? `${defaultColorScheme}.600` : 'transparent'}
                >{answerChoice.text}</StyledButton>
              );
            }
          })}
        </ButtonGroup>
      }
      {question.helpText &&
        <FormHelperText>{question.helpText}</FormHelperText>
      }
    </FormControl>
  );
}
