import React from 'react';
import {
  Modal, ModalContent, ModalHeader, ModalCloseButton, UseDisclosureProps, ModalOverlay, ModalBody,
  ModalFooter, Text, DarkMode, useBreakpointValue, Stack, Link
} from '@chakra-ui/react';

import { IQuestionGroup } from '../interfaces/Models';
import { StyledButton } from '../styled-components/StyledButton';

interface IProps extends UseDisclosureProps {
  questionGroup: IQuestionGroup;
}

export const OnSubmitEntryModal = (props: IProps) => {
  const { isOpen, onClose, questionGroup } = props;

  const modalSize = useBreakpointValue(["xs", "md"]);

  const onCreateGroupClick = () => {
    window.open(`${window.location.origin}/create-group/${questionGroup.id}`, '_blank');
  };

  return (
    <DarkMode>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={modalSize}
      >
        <ModalOverlay />
        <ModalContent backgroundColor={"background"}>
          <ModalHeader>Your picks are in!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={[2, 4]}>
              <Text>
                Your group page will update live with rankings as the event goes on, so be sure to come back to this url once it starts (you will also then be able to see what picks everyone else had).
              </Text>
              <Text>
                If you would like to create your own new group for {questionGroup.name}, click the button below.
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <StyledButton
              mr={3}
              colorScheme="brandColorScheme"
              onClick={onCreateGroupClick}
            >
              Create New Group
            </StyledButton>
            <StyledButton
              colorScheme="brandColorScheme"
              onClick={onClose}
              variant="ghost"
            >
              Close
            </StyledButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkMode>
  );
};
