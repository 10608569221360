import React from 'react';

import { Textarea, TextareaProps } from '@chakra-ui/react';

interface IProps extends TextareaProps {
  colorScheme: string;
}

export const StyledTextarea = (props: IProps) => {
  const { colorScheme } = props;

  return (
    <Textarea
      bgColor={`${colorScheme}.500`}
      color="white"
      _hover={{
        backgroundColor: `${colorScheme}.600`
      }}
      _focus={{
        color: `${colorScheme}.900`,
        borderColor: `${colorScheme}.700`,
        border: '1px solid',
        backgroundColor: `${colorScheme}.100`,
      }}
      _selected={{
        outlineColor: `${colorScheme}.900`
      }}
      verticalAlign="bottom"
      {...props}
    />
  )
}
