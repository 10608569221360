import React, { useState, useEffect, useCallback } from 'react';
import { mapValues, forEach, keys, values, map, every, trim, find, size } from 'lodash';
import {
  Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody,
  DrawerFooter, Stack, FormLabel, FormControl, useBreakpointValue, FormHelperText, Heading,
  Checkbox
} from '@chakra-ui/react';

import { createEntry, fetchEntriesByQuestionGroupIdAndEmail } from '../api/Api';
import { ChoiceQuestionInput } from '../components/ChoiceQuestionInput';
import { NumberQuestionInput } from '../components/NumberQuestionInput';
import { StyledInput } from '../styled-components/StyledInput';
import { StyledButton } from '../styled-components/StyledButton';
import { IEntry, IEntryWithGroup, IGroup, IQuestionDict, IQuestionGroup, IAnswerDict, QuestionTypes } from '../interfaces/Models';
import { emailRegex } from '../utils/InputUtils';
import { IEntryInput } from '../interfaces/Inputs';
import { ExistingEntries } from '../components/ExistingEntries';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  group: IGroup;
  questionGroup: IQuestionGroup;
  questionDict: IQuestionDict;
  isEntryNameUnique: (name: string) => boolean;
  isEntryEmailUnique: (email: string) => boolean;
  newEntryCallback: (newEntry: IEntry) => void;
}

type IAnswerDictInput = Record<string, string>;

export const SubmitEntryDrawer = (props: IProps) => {
  const {
    isOpen, onClose, group, questionGroup, questionDict, isEntryNameUnique,
    isEntryEmailUnique, newEntryCallback
  } = props;

  const inputSize = useBreakpointValue(["md", "lg"]);
  const inputWidth = useBreakpointValue([24, 40]); // in rem

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [entryFeeCheckbox, setEntryFeeCheckbox] = useState(false);
  const [answerDict, setAnswerDict] = useState<IAnswerDictInput>(mapValues(questionDict, () => ""));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);

  const [existingEntries, setExistingEntries] = useState<IEntryWithGroup[]>([]);
  const [selectedExistingEntryId, setSelectedExistingEntryId] = useState<string>();
  
  const isEmailInvalid = email && !emailRegex.test(email);
  const isNameInvalid = !isEntryNameUnique(name);
  const isEmailUnique = isEntryEmailUnique(email);

  const questions = map(questionGroup.questionIds, (questionId) => questionDict[questionId]);

  const setAnswerForQuestionId = (questionId: string) => {
    return (value: string) => {
      const newAnswersDict = {
        ...answerDict,
        [questionId]: value, 
      };
      setAnswerDict(newAnswersDict);
    };
  };

  const isSubmitDisabled = !every([
    name, email, group.entryFee ? entryFeeCheckbox : true, !isNameInvalid, !isEmailInvalid, isEmailUnique, ...values(answerDict)
  ], Boolean);

  const getFormattedAnswers = (): IAnswerDict => {
    const formattedAnswers: IAnswerDict = {};
    forEach(keys(answerDict), (id) => {
      if (questionDict[id].type === QuestionTypes.Number) {
        formattedAnswers[id] = { numberAnswer: parseFloat(answerDict[id]) };
      } else if (questionDict[id].type === QuestionTypes.Choice) {
        formattedAnswers[id] = { choiceAnswerId: answerDict[id] };
      }
    });
    return formattedAnswers;
  }

  const resetDrawerData = () => {
    setIsSubmitting(false);
    setHasApiError(false);
    setName('');
    setEmail('');
    setEntryFeeCheckbox(false);
    setAnswerDict(mapValues(questionDict, () => ''));
  }

  const onSubmit = async () => {
    setIsSubmitting(true);

    const groupId = group.id;
    const groupPassword = group.password;

    const entryInput: IEntryInput = {
      name: trim(name),
      email: trim(email),
      groupId,
      answers: getFormattedAnswers(),
    }

    const { data: entryData, hasError } = await createEntry(entryInput, groupId, groupPassword);
    if (hasError) {
      setHasApiError(true);
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    resetDrawerData();
    onClose();
    newEntryCallback(entryData);
  }

  useEffect(() => {
    (async () => {
      if (email && !isEmailInvalid && isEmailUnique) {
        const { data: existingEntries, hasError: existingEntriesError } = await fetchEntriesByQuestionGroupIdAndEmail(group.questionGroupId, email);
        if (!existingEntriesError) {
          setExistingEntries(existingEntries);
        }
        if (size(existingEntries) > 0) {
          setSelectedExistingEntryId(existingEntries[0].id);
        }
      }
    })();
  }, [email, isEmailInvalid, isEmailUnique]);

  const onPopulateExistingEntry = useCallback(() => {
    const entry = find(existingEntries, { id: selectedExistingEntryId });
    if (entry) {
      const formattedAnswers = mapValues(entry.answers, (answer) => {
        if (answer.numberAnswer) {
          return answer.numberAnswer.toString();
        } else if (answer.choiceAnswerId) {
          return answer.choiceAnswerId;
        }
      });
      setAnswerDict(formattedAnswers);
    }
  }, [existingEntries, selectedExistingEntryId]);

  return (
    <Drawer
      isOpen={isOpen}
      size="lg"
      placement="right"
      onClose={onClose}
      returnFocusOnClose={false}
    >
      <DrawerOverlay>
        <DrawerContent bg="background">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading fontSize={["2xl", "3xl"]}>Select your picks!</Heading>
            {hasApiError &&
              <Heading color="red.300" fontSize={["lg", "1xl"]} pt={["4px", "8px"]}>Hmm something went wrong. Mind trying again?</Heading>
            }
          </DrawerHeader>
          <DrawerBody px={["0.5rem", "1rem"]}>
            <Stack spacing={[3, 5]} align="center" px={["0px", "10px"]}>
              <FormControl maxWidth={`${inputWidth}rem`} isRequired>
                <FormLabel>Name</FormLabel>
                <StyledInput
                  colorScheme="gray"
                  size={inputSize}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  isInvalid={isNameInvalid}
                />
                {isNameInvalid &&
                  <FormHelperText color="red.300">That name already has an entry.</FormHelperText>
                }
              </FormControl>
              <FormControl maxWidth={`${inputWidth}rem`} isRequired>
                <FormLabel>Email</FormLabel>
                <StyledInput
                  colorScheme="gray"
                  size={inputSize}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  isInvalid={isEmailInvalid}
                />
                {isEmailInvalid &&
                  <FormHelperText color="red.300">Please enter a valid email.</FormHelperText>
                }
                {!isEmailUnique &&
                  <FormHelperText color="red.300">That email already has an entry.</FormHelperText>
                }
              </FormControl>
              <FormControl maxWidth={`${inputWidth}rem`}>
                <ExistingEntries
                  existingEntries={existingEntries}
                  selectedExistingEntryId={selectedExistingEntryId}
                  setSelectedExistingEntryId={setSelectedExistingEntryId}
                  onPopulateExistingEntry={onPopulateExistingEntry}
                />
              </FormControl>
              {map(questions, (q, index) => {
                if (q.type === QuestionTypes.Choice) {
                  return (
                    <ChoiceQuestionInput
                      key={q.id}
                      question={q}
                      order={index}
                      inputChoiceId={answerDict[q.id]}
                      setAnswerChoice={setAnswerForQuestionId(q.id)}
                      buttonSize={inputSize}
                      inputWidth={inputWidth}
                    />
                  );
                } else if (q.type === QuestionTypes.Number) {
                  return (
                    <NumberQuestionInput
                      key={q.id}
                      question={q}
                      order={index}
                      inputNumber={answerDict[q.id]}
                      setAnswerNumber={setAnswerForQuestionId(q.id)}
                      inputSize={inputSize}
                      inputWidth={inputWidth}
                    />
                  );
                }
              })}
              {group.entryFee && group.entryFee > 0 &&
                <FormControl
                  maxWidth={`${inputWidth}rem`}
                  isRequired
                  mb={["10px", "20px"]}
                >
                  <FormLabel>Entry Fee</FormLabel>
                  <Checkbox
                    size={inputSize}
                    colorScheme="gray"
                    onChange={() => setEntryFeeCheckbox(!entryFeeCheckbox)}
                    spacing="16px"
                  >
                    I will pay the entry fee of ${group.entryFee} to the group creator.
                  </Checkbox>
                </FormControl>
              }
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <StyledButton
              variant="ghost"
              mr={3}
              onClick={onClose}
              size={inputSize}
            >
              Cancel
            </StyledButton>
            <StyledButton
              colorScheme="brandColorScheme"
              size={inputSize}
              isDisabled={isSubmitDisabled}
              isLoading={isSubmitting}
              loadingText={"Submitting 🎉"}
              onClick={onSubmit}
            >
              Submit 🎉
            </StyledButton>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
