import React from 'react';

import { InputAddon, InputAddonProps } from '@chakra-ui/react';

interface IProps extends InputAddonProps {
  colorScheme: string
}

export const StyledInputAddon = (props: IProps) => {
  const { colorScheme } = props;
  return (
    <InputAddon
      bgColor={`${colorScheme}.500`}
      color="white"
      fontWeight="bold"
      {...props}
    />
  )
}
