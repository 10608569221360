import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { forEach, isNaN, map, isInteger } from 'lodash';
import {
  Heading, Center, Stack, Box, useBreakpointValue, FormControl,
  FormLabel, FormHelperText, VStack
} from '@chakra-ui/react';

import { createEvent, createQuestionsBulk, createQuestionGroup } from '../../api/Api';
import { NewQuestionCard, INewQuestion } from '../../components/NewQuestionCard';
import { QuestionTypes } from '../../interfaces/Models';
import { StyledButton } from '../../styled-components/StyledButton';
import { StyledInput } from '../../styled-components/StyledInput';
import { StyledTextarea } from '../../styled-components/StyledTextarea';
import { IEventInput, IQuestionGroupInput, IQuestionInput } from '../../interfaces/Inputs';

const emptyQuestion: INewQuestion = {
  text: '',
  title: '',
  helpText: '',
  type: QuestionTypes.Choice,
  choices: [],
  multiplier: 1,
}

export const CreateQuestionGroupPage = () => {
  const history = useHistory();

  const [apiKey, setApiKey] = useState('');

  const [newQuestions, setNewQuestions] = useState<INewQuestion[]>([emptyQuestion]);
  const [questionGroupName, setQuestionGroupName] = useState('');
  const [questionGroupInfoText, setQuestionGroupInfoText] = useState('');
  const [acceptEntriesTil, setAcceptEntriesTil] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState([]);

  const addQuestionButtonSize = useBreakpointValue(["sm", "md"]);
  const biggerSize = useBreakpointValue(["md", "lg"]);

  const validateNewQuestion = (newQuestion: INewQuestion, index: number): string[] => {
    const validationErrors = [];

    const questionNum = index + 1;

    if (!newQuestion.text) {
      validationErrors.push(`Q${questionNum} needs question text.`);
    }

    if (newQuestion.type === QuestionTypes.Choice) {
      if (newQuestion.choices.length <= 1) {
        validationErrors.push(`Q${questionNum} needs at least 2 choices.`);
      }

      if (newQuestion.isTieBreaker) {
        validationErrors.push(`Choice question Q${questionNum} can't be a tie-breaker.`);
      }

      forEach(newQuestion.choices, (choice, index) => {
        const choiceNum = index + 1;
        if (!choice.text) {
          validationErrors.push(`Q${questionNum} choice ${choiceNum} needs text.`);
        }
      });
    }

    if (!isInteger(newQuestion.multiplier)) {
      validationErrors.push(`Q${questionNum} multipler must be an integer`);
    }

    return validationErrors;
  };

  const onCreateQuestionGroupClick = async () => {
    setIsSubmitting(true);

    const validationErrors = [];

    if (!apiKey) {
      validationErrors.push('API key is required.');
    }

    // Validate the user inputs
    if (!questionGroupName) {
      validationErrors.push('Name is required.')
    }

    if (!acceptEntriesTil) {
      validationErrors.push('Accept Entries Til is required.');
    }

    if (acceptEntriesTil) {
      const acceptEntriesTilDate = new Date(acceptEntriesTil);
      if (isNaN(acceptEntriesTilDate.getDate())) {
        validationErrors.push('Accept Entries Til is not in an acceptable format (should be ISO 8601).');
      }
    }

    if (newQuestions.length === 0) {
      validationErrors.push('At least one question is required.');
    }

    let numTieBreakers = 0;

    forEach(newQuestions, (newQuestion, index) => {
      const errors = validateNewQuestion(newQuestion, index);
      validationErrors.push(...errors);
      if (newQuestion.isTieBreaker) {
        numTieBreakers += 1;
      }
    });

    if (numTieBreakers > 1) {
      validationErrors.push('Only allowed one tie-breaker question.');
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      setHasError(true);
      setIsSubmitting(false);
      return;
    }

    const eventInput: IEventInput = { name: questionGroupName };
    const { data: event, hasError: createEventError } = await createEvent(eventInput, apiKey);
    if (createEventError) {
      setErrors(['Error from API when creating event.']);
      setHasError(true);
      setIsSubmitting(false);
      return;
    }

    const questionsInput: IQuestionInput[] = map(newQuestions, (newQ: INewQuestion): IQuestionInput => {
      return {
        ...newQ,
        eventId: event.id,
      };
    });
    const { data: questions, hasError: createQuestionsBulkError } = await createQuestionsBulk(questionsInput, apiKey);
    if (createQuestionsBulkError) {
      setErrors(['Error from API when creating questions.']);
      setHasError(true);
      setIsSubmitting(false);
      return;
    }

    const questionIds = map(questions, (q) => q.id);
    const questionGroupInput: IQuestionGroupInput = {
      name: questionGroupName,
      infoText: questionGroupInfoText,
      acceptEntriesTil,
      questionIds,
      isPublic: true,
    };
    const { data: questionGroup, hasError: createQuestionGroupError } = await createQuestionGroup(questionGroupInput, apiKey);
    if (createQuestionGroupError) {
      setErrors(['Error from API when creating question group.']);
      setHasError(true);
      setIsSubmitting(false);
      return;
    }

    setErrors([]);
    setHasError(false);
    setIsSubmitting(false);
    history.push(`/admin/question-group/${questionGroup.id}`);
  };

  return (
    <Stack spacing={[3, 5]} align="center" justifyContent="center" pb={["20px", "40px"]}>
      <Center>
        <Heading fontSize={["2xl", "4xl"]} px="10x" pt={["10px", "20px"]}>Create Question Group</Heading>
      </Center>
      <FormControl maxWidth="lg" px="10px" isRequired>
        <FormLabel>API Key</FormLabel>
        <StyledInput
          value={apiKey}
          onChange={(event) => setApiKey(event.target.value)}
          size={biggerSize}
          fontWeight="normal"
          maxWidth="lg"
          colorScheme="gray"
          variant="filled"
        />
      </FormControl>
      <FormControl maxWidth="lg" px="10px" isRequired>
        <FormLabel>Question Group Name</FormLabel>
        <StyledInput
          value={questionGroupName}
          onChange={(event) => setQuestionGroupName(event.target.value)}
          size={biggerSize}
          fontWeight="normal"
          maxWidth="lg"
          colorScheme="gray"
          variant="filled"
        />
      </FormControl>
      <FormControl maxWidth="lg" px="10px">
        <FormLabel>Question Group Info Text</FormLabel>
        <StyledTextarea
          value={questionGroupInfoText}
          onChange={(event) => setQuestionGroupInfoText(event.target.value)}
          rows={2}
          size={biggerSize}
          maxWidth="lg"
          colorScheme="gray"
          variant="filled"
        />
      </FormControl>
      <FormControl maxWidth="lg" px="10px" isRequired>
        <FormLabel>Accept Entries Til (ISO 8601)</FormLabel>
        <StyledInput
          value={acceptEntriesTil}
          onChange={(event) => setAcceptEntriesTil(event.target.value)}
          size={biggerSize}
          fontWeight="normal"
          maxWidth="lg"
          colorScheme="gray"
          variant="filled"
        />
      </FormControl>
      <Box width="100%">
        {newQuestions.map((newQuestion, index) => {
          return (
            <Box py={["4px", "8px"]} key={index}>
              <NewQuestionCard
                questionData={newQuestion}
                setQuestionData={(questionData) => {
                  const newNewQuestions = [...newQuestions];
                  newNewQuestions[index] = questionData;
                  setNewQuestions(newNewQuestions);
                }}
              />
            </Box>
          );
        })}
      </Box>
      <StyledButton
        size={addQuestionButtonSize}
        onClick={() => {
          const newNewQuestions = [
            ...newQuestions,
            emptyQuestion
          ];
          setNewQuestions(newNewQuestions);
        }}
      >
        Add Question
      </StyledButton>
      <FormControl>
        <VStack>
          <StyledButton
            size={biggerSize}
            isDisabled={false}
            isLoading={isSubmitting}
            loadingText={"Creating Question Group"}
            onClick={onCreateQuestionGroupClick}
          >
            Create Question Group
          </StyledButton>
          {hasError &&
            <FormHelperText px="10px" color="red.300">
              {errors.map((error, index) => {
                return (
                  <Center key={index}>{error}</Center>
                );
              })}
            </FormHelperText>
          }
        </VStack>
      </FormControl>
    </Stack>
  );
};
