import React from 'react';
import { FormHelperText, FormLabel, Stack, useBreakpointValue } from "@chakra-ui/react";

import { IEntryWithGroup } from "../interfaces/Models";
import { StyledButton } from "../styled-components/StyledButton";
import { StyledSelect } from "../styled-components/StyledSelect";
import { map, size } from 'lodash';
import { widthNumToString } from '../utils/InputUtils';

interface IProps {
  existingEntries: IEntryWithGroup[];
  selectedExistingEntryId: string;
  setSelectedExistingEntryId: (id: string) => void;
  onPopulateExistingEntry: () => void;
}

export const ExistingEntries = ({
  existingEntries,
  selectedExistingEntryId,
  setSelectedExistingEntryId,
  onPopulateExistingEntry,
}: IProps) => {
  const inputSize = useBreakpointValue(["md", "lg"]);
  const entrySelectWidth = useBreakpointValue([14, 24]);
  const copyButtonWidth = useBreakpointValue([6, 8]);

  if (!existingEntries || size(existingEntries) === 0) {
    return null;
  }

  return (
    <>
    <FormLabel>
      {size(existingEntries) === 1 ?
        'You already have an entry in another group for this event. Would you like to copy your picks from that group?'
        :
        `You already have entries in ${size(existingEntries)} other groups for this event. Would you like to copy your picks from one of those groups?`
      }
    </FormLabel>
    <Stack direction="row" justify="space-between">
      <StyledSelect
        value={selectedExistingEntryId}
        onChange={(event) => setSelectedExistingEntryId(event.target.value)}
        fontWeight="normal"
        size={inputSize}
        width={widthNumToString(entrySelectWidth)}
        colorScheme="gray"
        variant="filled"
      >
        {map(existingEntries, (entry) => {
          return <option key={entry.id} value={entry.id}>{entry.groupName}</option>;
        })}
      </StyledSelect>
      <StyledButton
        onClick={onPopulateExistingEntry}
        variant="solid"
        size={inputSize}
        width={widthNumToString(copyButtonWidth)}
      >Copy 🪄</StyledButton>
    </Stack>
    <FormHelperText>Don't worry, you will still get the chance to edit these picks before submitting.</FormHelperText>
    </>
  )
}