export interface IBaseModel {
  dateCreated: string;
  dateUpdated: string;
}

export interface IGroup extends IBaseModel {
  id: string;
  urlId: string;
  name: string;
  email: string;
  notes?: string;
  entryFee: number;
  entryFeeInstructions?: string;
  awardDistributions: number[];
  password: string;
  questionGroupId: string;
}

export interface IEvent extends IBaseModel {
  id?: string;
  name: string;
}

export interface IQuestionGroup extends IBaseModel {
  id: string;
  name: string;
  infoText?: string;
  questionIds: string[];
  acceptEntriesTil: string;
  isPublic: boolean;
}

export enum QuestionTypes {
  Number='Number',
  Choice='Choice',
}

export interface IColorScheme {
  50: string,
  100: string,
  200: string,
  300: string,
  400: string,
  500: string,
  600: string,
  700: string,
  800: string,
  900: string,
}

export interface IQuestionChoice {
  id: string;
  text: string;
  colorScheme?: IColorScheme;
}

export interface IQuestion extends IBaseModel {
  id: string;
  eventId?: string;
  text: string;
  title?: string;
  helpText?: string;
  type: QuestionTypes;
  choices?: IQuestionChoice[];
  numberColorScheme?: IColorScheme; // for NUMBER questions only one scheme
  choiceResultId?: string;
  numberResult?: number;
  isTieBreaker?: boolean;
  multiplier?: number;
}

export interface IQuestionDict {
  [questionId: string]: IQuestion;
}

export interface IAnswer {
  numberAnswer?: number;
  choiceAnswerId?: string;
}

export interface IAnswerDict {
  [questionId: string]: IAnswer
}

export interface IEntry extends IBaseModel {
  id: string;
  name: string;
  email?: string;
  questionGroupId?: string;
  groupId: string;
  answers: IAnswerDict;
}

export interface IEntryWithGroup extends IEntry {
  groupName: string;
}
