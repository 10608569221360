import React from 'react';
import { isArray, toInteger } from 'lodash';
import {
  FormControl, FormLabel, Container, useBreakpointValue, Stack, Flex, Box
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'

import { StyledInput } from '../styled-components/StyledInput';
import { StyledSelect } from '../styled-components/StyledSelect';
import { StyledTextarea } from '../styled-components/StyledTextarea';
import { StyledSwitch } from '../styled-components/StyledSwitch';
import { StyledIconButton } from '../styled-components/StyledIconButton';
import { IQuestionInput } from '../interfaces/Inputs';
import { QuestionTypes } from '../interfaces/Models';
import { ColorPickerIcon } from './ColorPickerIcon';
import { generateColorSwatch } from '../utils/ColorUtils';

export interface INewQuestion extends Omit<IQuestionInput, 'eventId'> {}

interface IProps {
  questionData: INewQuestion;
  setQuestionData: (questionData: INewQuestion) => void;
}

export const NewQuestionCard = (props: IProps) => {
  const { questionData, setQuestionData } = props;
  const inputSize = useBreakpointValue(["sm", "md"]);
  const choiceInputSize = useBreakpointValue(["xs", "sm"]);

  return (
    <Container
      borderWidth="1px"
      borderRadius="lg"
      centerContent
      py="10px"
    >
      <Stack spacing={[1, 2]} width="100%" align="center">
        <FormControl maxWidth="lg" px="10px" isRequired>
          <FormLabel>Question Text</FormLabel>
          <StyledTextarea
            value={questionData.text}
            onChange={(event) => {
              setQuestionData({
                ...questionData,
                text: event.target.value
              });
            }}
            rows={2}
            size={inputSize}
            maxWidth="lg"
            colorScheme="gray"
            variant="filled"
          />
        </FormControl>
        <FormControl maxWidth="lg" px="10px">
          <FormLabel>Title</FormLabel>
          <StyledInput
            value={questionData.title}
            onChange={(event) => {
              setQuestionData({
                ...questionData,
                title: event.target.value
              });
            }}
            size={inputSize}
            fontWeight="normal"
            maxWidth="lg"
            colorScheme="gray"
            variant="filled"
          />
        </FormControl>
        <FormControl maxWidth="lg" px="10px">
          <FormLabel>Help Text</FormLabel>
          <StyledInput
            value={questionData.helpText}
            onChange={(event) => {
              setQuestionData({
                ...questionData,
                helpText: event.target.value
              });
            }}
            size={inputSize}
            fontWeight="normal"
            maxWidth="lg"
            colorScheme="gray"
            variant="filled"
          />
        </FormControl>
        <Stack direction="row" justify="space-between">
          <FormControl maxWidth="lg" px="10px" isRequired>
            <FormLabel>Question Type</FormLabel>
            <StyledSelect
              value={questionData.type}
              onChange={(event) => {
                const newQuestionType = event.target.value as QuestionTypes;
                const newQuestionData = {
                  ...questionData,
                  type: newQuestionType
                };
                if (newQuestionType === QuestionTypes.Choice) {
                  delete newQuestionData.numberColorScheme;
                  delete newQuestionData.isTieBreaker;
                } else if (newQuestionType === QuestionTypes.Number) {
                  newQuestionData.choices = [];
                }
                setQuestionData(newQuestionData);
              }}
              size={inputSize}
              fontWeight="normal"
              maxWidth="lg"
              colorScheme="gray"
              variant="filled"
            >
              <option key={QuestionTypes.Choice}>Choice</option>
              <option key={QuestionTypes.Number}>Number</option>
            </StyledSelect>
          </FormControl>
          <FormControl maxWidth="lg" px="10px" isRequired>
            <FormLabel>Multiplier</FormLabel>
            <StyledInput
            value={questionData.multiplier}
              onChange={(event) => {
                const newMultiplier = toInteger(event.target.value) ? toInteger(event.target.value) : null;
                setQuestionData({
                  ...questionData,
                  multiplier: newMultiplier,
                });
              }}
              size={inputSize}
              fontWeight="normal"
              maxWidth="lg"
              colorScheme="gray"
              variant="filled"
            />
          </FormControl>
        </Stack>
        {questionData.type === QuestionTypes.Number &&
          <Flex>
            <FormControl
              display="flex"
              alignItems="center"
              mr={["2em", "6em"]}
              size={choiceInputSize}
            >
              <FormLabel mb="0">
                Is tiebreaker?
              </FormLabel>
              <StyledSwitch
                mt="2px"
                size={inputSize}
                colorScheme="gray"
                isChecked={questionData.isTieBreaker}
                onChange={() => {
                  const newIsTieBreaker = !questionData.isTieBreaker;
                  setQuestionData({
                    ...questionData,
                    isTieBreaker: newIsTieBreaker,
                  });
                }}
              />
            </FormControl>
            <ColorPickerIcon
              onColorChange={color => {
                const newNumberColorScheme = generateColorSwatch(color);
                setQuestionData({
                  ...questionData,
                  numberColorScheme: newNumberColorScheme,
                });
              }}
              buttonSize={choiceInputSize}
            />
          </Flex>
        }
        {questionData.type === QuestionTypes.Choice &&
          <Stack spacing={[1, 2]} width="100%" align="center" px={["1em", "3em"]}>
            {questionData.choices?.map((choice, index) => {
              return (
                <Flex position="relative" width="100%" key={choice.id}>
                  <Box left={0} position="absolute">
                    <StyledIconButton
                      aria-label="delete"
                      icon={<DeleteIcon color="red.500" />}
                      size={choiceInputSize}
                      onClick={() => {
                        const newChoices = [...questionData.choices];
                        newChoices.splice(index, 1);
                        const choicesWithNewId = newChoices.map((choice, index) => {
                          return {
                            ...choice,
                            id: index.toString(),
                          };
                        });
                        setQuestionData({
                          ...questionData,
                          choices: choicesWithNewId,
                        });
                      }}
                    />
                  </Box>
                  <FormControl px="10px" marginLeft="30" marginRight="30">
                    <StyledInput
                      value={choice.text}
                      onChange={(event) => {
                        const newChoices = [...questionData.choices];
                        newChoices[index] = {
                          ...questionData.choices[index],
                          text: event.target.value,
                        }
                        setQuestionData({
                          ...questionData,
                          choices: newChoices,
                        });
                      }}
                      size={choiceInputSize}
                      fontWeight="normal"
                      // maxWidth="md"
                      colorScheme="gray"
                      variant="filled"
                    />
                  </FormControl>

                  <Box right={0} position="absolute">
                    <ColorPickerIcon
                      onColorChange={color => {
                        const newChoiceColorScheme = generateColorSwatch(color);
                        const newChoices = [...questionData.choices];
                        newChoices[index] = {
                          ...questionData.choices[index],
                          colorScheme: newChoiceColorScheme,
                        }
                        setQuestionData({
                          ...questionData,
                          choices: newChoices,
                        });
                      }}
                      buttonSize={choiceInputSize}
                    />
                  </Box>
                </Flex>
                // </Container>
              );
            })}
            <StyledIconButton
              onClick={() => {
                let newChoices;
                if (isArray(questionData.choices)) {
                  newChoices = [
                    ...questionData.choices,
                    { id: (questionData.choices.length).toString() }
                  ]
                } else {
                  newChoices = [
                    { id: '0' }
                  ];
                }
                setQuestionData({
                  ...questionData,
                  choices: newChoices,
                });
              }}
              size={choiceInputSize}
              icon={<AddIcon />}
              aria-label="add choice"
            />
          </Stack>
        }
      </Stack>
    </Container>
  );
}
