import React from 'react';

import { Switch, SwitchProps } from '@chakra-ui/react';

interface IProps extends SwitchProps {
}

export const StyledSwitch = (props: IProps) => {
  return (
    <Switch
      {...props}
    />
  );
}
