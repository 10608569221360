import React, { forwardRef } from 'react';
import { IconButton, IconButtonProps, LightMode } from '@chakra-ui/react';

interface IProps extends IconButtonProps {
  ref: any
}

export const StyledIconButton = forwardRef((props: IProps, ref?: any) => {
  return (
    <LightMode>
      <IconButton
        _focus={{
          boxShadow: "none !important"
        }}
        ref={ref}
        colorScheme="whiteAlpha"
        {...props}
      />
    </LightMode>
  );
});
